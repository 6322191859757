.loging {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: #333;
  opacity: 0.6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.loging p{
  color: #fff;
}
.progress {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.root {
  position: relative;
  min-height: 100vh;
  width: 100%;
}
.vr_view {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100vh;
}
.vr_view iframe {
  border: none;
}
.background {
  background-color: #0b86f2;
}
.logo {
  position: absolute;
  right: 20px;
  top: 20px;
}
.logo img {
  width: 220px;
}
.logo p {
  text-align: center;
  color: #fff;
  font-size: 21px;
  font-weight: bold;
}
.container {
  background-color: #0071d4;
  border-radius: 10px;
  box-shadow: inset -2px -2px 3px rgba(0, 0, 0, 0.6),
  inset 2px 2px 3px rgba(255, 245, 245, 0.2);
}
.form_scale {
  width: 800px;
  height: 450px;
}
.form_scale {
  color: #fff;
  text-align: center;
}
.form_item {
  width: 60%;
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.form_item label {
  color: #fff;
  font-size: 18px;
  text-align: left;
}
.form_item input {
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  color: #333;
}
.form_scale p {
  color: #fff;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
.form_scale p:hover {
  opacity: 0.6;
}
.form_button {
  display: flex;
  justify-content: center;
}
.form_button button{
  width: 300px;
  height: 40px;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 5px;
}

.home_scale {
  position: relative;
  width: 350px;
  height: 350px;
  cursor: pointer;
}
.home_scale h2 {
  position: absolute;
  text-align: right;
  color: #fff;
  margin-right: 20px;
  margin-bottom: 20px;
  bottom: 0;
  right: 0;
}
.bottom_logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
}
.bottom_logo img {
  width: 410px;
}
.bottom_logo p {
  font-size: 38px;
  font-weight: bold;
  color: #fff;
}

.info_container {
  width: 300px;
  margin: auto;
}
.mypage_info label {
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}
.mypage_info p {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.6),
  inset -2px -2px 3px rgba(255, 245, 245, 0.2);
  font-size: 24px;
}

.logout_button {
  display: flex;
  justify-content: center;
}
.logout_button button {
  color: #fff;
  border: none;
  border-radius: 5px;
  background-color: black;
  width: 200px;
  height: 40px;
}




.space_module_small {
    height: 20px;
}
.space_module_midium {
    height: 40px;
}
.space_module_large {
    height: 60px;
}
.space_module_small-w {
    width: 20px;
}
.space_module_midium-w {
    width: 40px;
}
.space_module_large-w {
    width: 60px;
}

h2 {
    font-size: 32px;
}

.p-all40 {
    padding: 40px;
}

.p-left-10 {
    padding-bottom: 10px;
}
.p-right-10 {
    padding-bottom: 10px;
}
.p-top-10 {
    padding-bottom: 10px;
}
.p-bottom-10 {
    padding-bottom: 10px;
}

.flex {
    display: flex;
    align-items: center;
}
.position-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}